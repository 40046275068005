.hero-wrapper {


}

.v-responsive__sizer {
    transition: padding-bottom .2s cubic-bezier(.25, .8, .5, 1);
    flex: 1 0 0px
}

.v-image__image, .v-image__placeholder {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(https://lime24.co.za/img/hero-bg.e7d42e62.png) center center;
    background-size: cover;
    max-height: 390px;
}

.v-image__image {
    background-repeat: no-repeat
}

.image-overlay {
    width: 100%;
    background: rgb(51 108 30 / 81%);
    height: 100%;
}

.content-wrapper {
    padding: 20px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}

.mobile-heading-h3 {
    color: #fff !important;
    caret-color: #fff !important;
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0;
    text-align: left
}

p.b-body-1 {
    font-size: 14px;
    line-height: 21px;
    letter-spacing: .01em;
    color: #fff
}

.get-money-button {
    display: block;
    width: 100%;
    border-radius: 5px;
    padding: 12px 10px;
    text-align: center;
    background-color: #db7309 !important;
    border-color: #db7309 !important;
    font-size: 16px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: .01em;
    color: #fff;
    text-transform: uppercase;
}
.home {
    margin-top: 380px;
    width: 100%;

}
.home .container {
    text-align: left;
}