/* Reset CSS */
* {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
}

body {
    font-family: 'GT Eesti Pro Display', sans-serif;
    background: #f6f7f8;
    color: #000;
    line-height: 1.6;
}

.container {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}

h1, h2, h3, h4, h5, p {
    margin-bottom: 15px;
    color: #333;
}

h1 {
    font-size: 2.5em;
    font-weight: 600;
}

h2 {
    font-size: 2em;
}

h3 {
    font-size: 1.75em;
}

h4 {
    font-size: 1.25em;
    font-weight: 500;
}

h5 {
    font-size: 1em;
    color: #666;
}

a {
    color: #ffdd2d;
    text-decoration: none;
}

a:hover {
    color: #fab619;
}

.platform h2 {
    font-size: 2.5em;
    background: linear-gradient(to right, #ffdd2d, #fab619);
    -webkit-background-clip: text;
    color: transparent;
}

.platform img {
    width: 350px;
    border-radius: 24px;
    margin-bottom: 12px;
}

.space_between {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
}

.promocod {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    margin: 20px 0;
    border: 2px dashed #333;
    border-radius: 10px;
    background: #f9f9f9;
    cursor: pointer;
    transition: all 0.3s ease;
}

.promocod:hover {
    border-color: #fab619;
    background: #fffaf0;
}

.promocod h1 {
    font-size: 3em;
    font-weight: 700;
    margin-bottom: 10px;
    color: #333;
}

.promocod h4 {
    font-size: 1.5em;
    color: #666;
}

.btn_class {
    display: flex;
    flex-direction: column;
    align-items: center;

}

.btn {
    display: inline-block;
    padding: 15px 30px;
    font-size: 1.2em;
    font-weight: 600;
    color: #fff;
    background: linear-gradient(to right, #ffdd2d, #fab619);
    border-radius: 30px;
    transition: background 0.3s ease;
    margin: 15px 0;
}

.btn:hover {
    background: linear-gradient(to right, #fab619, #ffdd2d);
}

.footer {
    margin-top: 30px;
    text-align: center;
    color: #999;
    padding: 10px 20px;
}

@media (max-width: 600px) {
    .platform {
        flex-direction: column;
    }

    .platform h2 {
        font-size: 2em;
    }

    .promocod {
        margin: 10px 0;
    }

    .btn {
        width: 100%;
    }
}
